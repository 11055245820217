<template>
  <div class="container">

    <div class="text-center mt-3 mb-3">
      <h4>Группы</h4>
    </div>

    <Button label="Добавить" icon="pi pi-plus" class="p-button-primary p-mr-2" @click="openAddGroupsModal"/>

    <Dialog header="Добавление группы" v-model:visible="displayAddGroupsModal" :style="{width: '50vw'}">

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Название группы</label>
        <div class="col-md-9">
          <InputText type="text" @change="changeNewSelectableGroupsData('name', $event)"/>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Уровень языка</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeNewSelectableGroupsData('language_level_id', $event)">
            <option
              v-for="(item, index) in [{id: 0, full_name: 'Выберите уровень языка'}, ...selectableGroups_form.languagesLevel] "
              :value="item.id"
              :key="index">{{ item.full_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Преподаватель</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeNewSelectableGroupsData('pps_id', $event)">
            <option
              v-for="(item, index) in [{id: 0, name: 'Выберите преподавателя'}, ...selectableGroups_form.teachers] "
              :value="item.id"
              :key="index">{{
                item.id != 0 ? item.lastname + ' ' + item.firstname + ' ' + item.middlename :
                  item.name
              }}
            </option>
          </select>
        </div>
      </div>


      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeAddGroupsModal" class="p-button-text"/>
        <Button label="Добавить" icon="pi pi-check" @click="postSelectableGroups" autofocus/>
      </template>
    </Dialog>

    <div class="mt-3 mb-3">
      <DataTable :value="selectableGroups_form.selectableGroupsInfos" :paginator="true" :rows="30"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[30,50,100]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column field="selectable_groups_name" header="Название группы"></Column>
        <Column header="ФИО преподавателя">
          <template #body="{data}">
            {{ data.lastname + ' ' + data.firstname }}
          </template>
        </Column>
        <Column header="Уровень языка">
          <template #body="{data}">
            {{ data.language_level_name }}
          </template>
        </Column>

        <Column :exportable="false" style="min-width:8rem">
          <template #body="{data}">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2"
                    @click="openEditGroupsModal(data.id)"/>
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mx-1"
                    @click="deleteGroupsConfirm(data.id)"/>
          </template>
        </Column>
      </DataTable>
    </div>


    <Dialog header="Редактировать" v-model:visible="displayEditGroupsModal" :modal="true" :style="{width: '50vw'}">
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Название группы</label>
        <div class="col-md-9">
          <InputText type="text" :value="selectableGroups_form.editSelectableGroups?.selectable_groups_name"
                     @change="changeSelectableGroupsData('name', $event)"/>
        </div>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Уровень языка</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeSelectableGroupsData('language_level_id', $event)">
            <option
              v-for="(item, index) in [{id: 0, full_name: 'Выберите уровень языка'}, ...selectableGroups_form.languagesLevel] "
              :value="item.id"
              :selected="item.id == selectableGroups_form.editSelectableGroups.language_level_id"
              :key="index">{{ item.full_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Преподаватель</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeSelectableGroupsData('pps_id', $event)">
            <option
              v-for="(item, index) in [{id: 0, name: 'Выберите преподавателя'}, ...selectableGroups_form.teachers] "
              :value="item.id"
              :selected="item.id == selectableGroups_form.editSelectableGroups.pps_id"
              :key="index">{{
                item.id != 0 ? item.lastname + ' ' + item.firstname + ' ' + item.middlename :
                  item.name
              }}
            </option>
          </select>
        </div>
      </div>
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" class="p-button-text" @click="closeEditGroupsModal"/>
        <Button label="Обновить" icon="pi pi-check" autofocus @click="putSelectableGroups"/>
      </template>
    </Dialog>
    <ConfirmDialog></ConfirmDialog>


  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SelectableGroups",
  components: {},

  data() {
    return {
      selectable_flow_id: +this.$route.query.selectable_flow_id || 1,
      displayAddGroupsModal: false,
      displayEditGroupsModal: false
    }
  },
  computed: {
    ...mapState('selectableGroups', ['selectableGroups_form']),
  },

  methods: {
    ...mapActions('selectableGroups', ['POST_SELECTABLE_GROUPS_DATA', 'GET_SELECTABLE_GROUPS_DATA', 'GET_LANGUAGES_LEVEL', 'DELETE_SELECTABLE_GROUPS_DATA',
      'GET_SELECTABLE_FLOW_BY_ID', 'GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM', 'PUT_SELECTABLE_GROUPS_DATA']),
    ...mapMutations('selectableGroups', ['SET_NEW_SELECTABLE_GROUPS_DATA', 'SET_SELECTABLE_FLOW_ID', 'SET_EDIT_SELECTABLE_GROUPS', 'CHANGE_SELECTABLE_GROUPS_DATA']),

    deleteGroupsConfirm(deleteSelectableGroupsId) {
      this.$confirm.require({
        message: 'Вы точно хотите удалить эту группу?',
        header: 'Потверждение удаления',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          await this.DELETE_SELECTABLE_GROUPS_DATA(deleteSelectableGroupsId)
          await this.GET_SELECTABLE_GROUPS_DATA()
          this.$message({text: 'Группа удалена'})
        }
      });
    },
    openAddGroupsModal() {
      this.displayAddGroupsModal = true
      //this.SET_SELECTABLE_FLOW_ID(this.selectable_flow_id)
    },
    closeAddGroupsModal() {
      this.displayAddGroupsModal = false
    },
    changeNewSelectableGroupsData(property, e, val = 'value') {
      let value = e.target[val]
      console.log(value, "value")
      this.SET_NEW_SELECTABLE_GROUPS_DATA({property, value})
    },
    async postSelectableGroups() {

      this.displayAddGroupsModal = false
      const res = await this.POST_SELECTABLE_GROUPS_DATA()
      if (res) {
        await this.GET_SELECTABLE_GROUPS_DATA()
        this.$message({text: 'Группа успешно создана'})
        return
      }
      this.$error({text: 'При создании возникла ошибка'})
    },
    async putSelectableGroups() {
      this.displayEditGroupsModal = false
      const res = await this.PUT_SELECTABLE_GROUPS_DATA()
      if (res) {
        await this.GET_SELECTABLE_GROUPS_DATA()
        this.$message({text: 'Группа обновлена'})
        return
      }
      this.$error({text: 'При обновлении возникла ошибка'})
    },
    openEditGroupsModal(editId) {
      console.log(editId, 'editId')
      const editSelectableGroups = this.selectableGroups_form.selectableGroupsInfos.find(i => i.id == editId)
      console.log(editSelectableGroups, 'editSelectableGroups')
      this.SET_EDIT_SELECTABLE_GROUPS({...editSelectableGroups})
      this.displayEditGroupsModal = true
    },
    closeEditGroupsModal() {
      this.displayEditGroupsModal = false
    },
    changeSelectableGroupsData(property, e, val = 'value') {
      let value = e.target[val]
      this.CHANGE_SELECTABLE_GROUPS_DATA({property, value})
    },

  },
  async mounted() {
    await this.GET_SELECTABLE_FLOW_BY_ID(this.selectable_flow_id)
    await this.GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM()

    await this.SET_SELECTABLE_FLOW_ID(this.selectable_flow_id)
    await this.GET_LANGUAGES_LEVEL()
    await this.GET_SELECTABLE_GROUPS_DATA()
  }

}
</script>

<style scoped>

</style>
